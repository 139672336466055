<template>
  <div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      filesImage: [
        "未标题-1.psd",
        "未标题-1.psd",
        "未标题-1.psd",
        "未标题-1.psd",
      ],
    };
  },
  methods: {
    //   success
    onchangemd(e) {
      //   if(this.filesImage)
      const files = e.target.files[0];
      const isLt5M = +files.size > 5242880 ? true : false;
      if (isLt5M) {
        this.$message({
          type: "worning",
          message: "图片大小不允许大于5M！",
        });
      } else if (
        files.type === "image/png" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        let formdata = new FormData();
        formdata.append("uploadFile", files);
        axios({
          method: "post",
          url: this.GLOBAL.file,
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          this.filesImage.push(files.name);
        });
        this.$message({
            type: 'success',
            message: '上传成功'
        })
      } else {
        this.$message({
          type: "worning",
          message: "图片格式不正确",
        });
      }
    },
    // files_D(e) {
    //   console.log("delete", e);
    // },
  },
};
</script>
<style scoped>
.files_list {
  margin: 5px;
  color: #606266;
  font-size: 12px;
}
.files_list_childer {
  /* background-color: rgb(167, 120, 120); */
  margin-bottom: 5px;
  /* width: 30%; */
}
.files_D {
  margin-left: 5%;
  display: none;
}
.files_list_childer:hover span {
  display: inline-block;
}
</style>